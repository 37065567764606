import { cFetch, token } from './apiUtils';
import config from '../config/config';
import { createQueryParam } from '../utils';

export function getSpectroList(apiParams) {
  let queryParam = createQueryParam(apiParams);
  return cFetch(
    `${config.API_URL}/api/iot/spectrometer/` +
      (queryParam ? '?' + queryParam : ''),
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
}

// export function getSpectroMeter(spectroId) {
//   return cFetch(`${config.API_URL}/api/c/spectrometer/${spectroId}/`, {
//     method: "GET",
//     headers: {
//       authorization: token(),
//     },
//   });
// }

export function AddSpectrometer(data) {
  return cFetch(`${config.API_URL}/api/iot/spectrometer/`, {
    method: 'POST',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function UpdateSpectrometer(id, data) {
  return cFetch(`${config.API_URL}/api/iot/spectrometer/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
}

export function spectroOverviewData(apiParams) {
  let queryParam = createQueryParam(apiParams);
  return cFetch(
    `${config.API_URL}/api/iot/analytic/spectro/` +
      (queryParam ? '?' + queryParam : ''),
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
}

export function spectroFilterOptions() {
  return cFetch(`${config.API_URL}/api/iot/filter/spectro/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}
