import { generateFromSvg } from '../../utils/UI/Icons';

import { ReactComponent as Dashboard } from './Sidebar/Dashboard.svg';
import { ReactComponent as Orders } from './Sidebar/Orders.svg';
import { ReactComponent as OrdersBlue } from './Header/OrdersBlue.svg';
import { ReactComponent as ChargeMix } from './Sidebar/ChargeMix.svg';
import { ReactComponent as Spectrometer } from './Sidebar/Spectrometer.svg';
import { ReactComponent as SpectrometerBlue } from './Header/SpectrometerBlue.svg';
import { ReactComponent as Chat } from './Sidebar/Chat.svg';
import { ReactComponent as BookADemo } from './Sidebar/BookADemo.svg';
import { ReactComponent as Inventory } from './Sidebar/Inventory.svg';
import { ReactComponent as Brochure } from './Sidebar/Brochure.svg';
import { ReactComponent as AboutSoftware } from './Sidebar/AboutSoftware.svg';
import { ReactComponent as UploadChargeMix } from './Header/UploadChargeMix.svg';
import { ReactComponent as Profile } from './Header/Profile.svg';
import { ReactComponent as PriceSavings } from './Dashboard/PriceSavings.svg';
import { ReactComponent as ElectricitySavings } from './Dashboard/ElectricitySavings.svg';
import { ReactComponent as LogReport } from './Sidebar/LogReport.svg';
import { ReactComponent as PowerInsight } from './Sidebar/PowerInsight.svg';
import { ReactComponent as PowerInsightsBlue } from './Header/PowerInsightsBlue.svg';
import { ReactComponent as RaiseRFQ } from './Sidebar/RaiseRFQ.svg';
import { ReactComponent as SpectrometerError } from './Spectrometer/Error.svg';

export const DashboardIcon = generateFromSvg(Dashboard);
export const InventoryIcon = generateFromSvg(Inventory);
export const OrdersIcon = generateFromSvg(Orders);
export const OrdersBlueIcon = generateFromSvg(OrdersBlue);
export const ChargeMixIcon = generateFromSvg(ChargeMix);
export const SpectrometerIcon = generateFromSvg(Spectrometer);
export const SpectrometerBlueIcon = generateFromSvg(SpectrometerBlue);
export const ChatIcon = generateFromSvg(Chat);
export const BookADemoIcon = generateFromSvg(BookADemo);
export const BrochureIcon = generateFromSvg(Brochure);
export const AboutSoftwareIcon = generateFromSvg(AboutSoftware);
export const UploadChargeMixIcon = generateFromSvg(UploadChargeMix);
export const ProfileIcon = generateFromSvg(Profile);
export const PriceSavingsIcon = generateFromSvg(PriceSavings);
export const ElectricitySavingsIcon = generateFromSvg(ElectricitySavings);
export const LogReportIcon = generateFromSvg(LogReport);
export const PowerInsightIcon = generateFromSvg(PowerInsight);
export const PowerInsightsBlueIcon = generateFromSvg(PowerInsightsBlue);
export const RaiseRFQIcon = generateFromSvg(RaiseRFQ);
export const SpectrometerErrorIcon = generateFromSvg(SpectrometerError);
