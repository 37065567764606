let config = {};

if (process.env.NODE_ENV === 'production') {
  const getEnvVar = key => ({ [key]: process.env[`REACT_APP_${key}`] });

  config = {
    ...getEnvVar('API_URL'),
    ...getEnvVar('BASE_URL'),
    ...getEnvVar('IMGIX_URL'),
    ...getEnvVar('ECOM_IMGIX_URL'),
    ...getEnvVar('PUBLIC_URL'),
    ...getEnvVar('GA_ACCESS_CODE'),
    ...getEnvVar('SPREADSHEET_ID'),
    ...getEnvVar('API_KEY'),
    ...getEnvVar('SHEET_ID'),
    ...getEnvVar('CLIENT_EMAIL'),
    ...getEnvVar('PRIVATE_KEY'),
    ...getEnvVar('ROLLBAR_ACCESS'),
    ...getEnvVar('AMPLITUDE_KEY'),
    ...getEnvVar('TAWK_API_KEY'),
    ...getEnvVar('TAWK_CHAT_ID'),
    ...getEnvVar('ROLLBAR_NAME'),
    ...getEnvVar('ENV')
  };
} else {
  config = {
    API_URL: 'https://staging-api.nowpurchase.com',
    BASE_URL: 'http://localhost:3000',
    IMGIX_URL: 'https://img1.nowpurchase.com/foundry/',
    ECOM_IMGIX_URL: 'https://img1.nowpurchase.com/assets/images/',
    PUBLIC_URL: 'http://localhost:3000/public/',
    GA_ACCESS_CODE: 'UA-130670864-1',
    SPREADSHEET_ID: '18OznMCgnuzeFGKEldccAHj-LFb83zrxMcWSHjVt4QCs',
    API_KEY: 'AIzaSyCVqmVQf-2Q7xXJfo4E_EqfWOLVt8H5vmA',
    SHEET_ID: '0',
    CLIENT_EMAIL: 'rfqsheet@triple-shift-296103.iam.gserviceaccount.com',
    PRIVATE_KEY:
      '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCwlJ80hkgcpeuy\nRwa9QkKMzWhBXKIRLCRdKYZfry3OjNtTjyPXyRqDUWUsbZ+VP+2lyeKqpyuxTLCW\nx1bbqsD04zlNlikFVmfENuS+lKnAMxVtJXPL7fp5wNokw3li32V4Dmrx5tTsQt/x\n5q9cs07MdCIxbs7Z0G9VIlqIUaT+FPKZxoCzP/TUsv8qZABdFGSQ/KmN/omwx2XF\nUxdte7wR9CI10uYrZXzcQvMFDaxoDL/XkbONxQBxox9xuaSokRQsmgalWvFZgosR\noNg97QihAWSPneH+VkQPcItXg4Vk3iL0GhPKABGkRNAPZAVnOWGfaz1FUMb1B9lk\n6EFultflAgMBAAECggEAAS9+QiQEp+yVQIUpjmu7dqaKZKXykJrzQ4zb0hii/hgE\nAPOdEf3Pp8IMI9g4kg/z0IEifHLS4EXbiaec9ayXzw0XfWXcsKR73d1ge8cTvK6b\nHjh6JoxaeibJUCXf0sIdNEkkfqtGErAAQOeDleO+S7Eb6akMynmTxcaNRQ0HRo1q\nlcLo20Y66x1TIicchiTPIUS11mi7SmuZ6VCfFyrH4zgg9irK+keYKWNvVilNLpdq\nKBCtebR807zzS/k12keBTuo8ZvVcdRzWDo/4y1f8NkSV/W/zyqpNnUyI/KfIj775\npUXEH2VAMuhyi+Nq/2owxazw4kxpcM8dPvlKSgkPoQKBgQDoiBQBe44ZHUktF//c\nf1DDqwbPbdmIjVIblop88amLm0N+rpK7w9tnTlzSR7qmt+aPAxN5J7p61P03Prt3\n5LP1a5DHlA4HwOdtt7NC3BTyY8zgmu0Yr/4374MZnd5NLFaN7LnwONcdkXawEB+E\nBnmaLrI8YvamfW0Fk09FCxOY5wKBgQDCZu/PZsWHJvshgMtPWqlJlJlIBQy+JBpV\nSTy7MEJyynZaDAxMsIQCXULMB20BGIeAQo2faY20BqsfhCcA2ATseSfl77FTNhuZ\nC/KcwyLRb/Df3p10AFM65tKDppQ4fp4Lk1qsM0Ud3zqpHGEUrNuY+Qj4BIHXnISv\nsd4Ruf/zUwKBgANfziKDX3l/0ksEOSNC+bINLkkeR/TSUCm0UogjnZk4BI+3CswM\nuIdUPMaUYeH/cyoKGmM8r1kO9Cm0EFZaDD54dB5hSJ7WLsWJz+EdfnRR/GwZ82zT\nSPYY1KcvgkVOamce00foGz8DmFxPr5Vj3qndGZsEuJ2L3mQhUhonYf5rAoGAIBek\nMSXzJ+uce5xqc9AwsC/y2fvTbbRCEKBFgVVnMtIPla/1e9eMA2Pn+gyjxxGIlPjn\nrlBbj0Omjk5frfj7XxplJsVaDbWZ+rsJMy3XmqGTLzHt5k+Dbe7bA9bFE0p7xsdt\nqY3RNCw839wKfsJGgktiWqdL1A9HrEV9tolxIncCgYAGVMDVxz8oQGE6kpfjV5QU\nNhbxbbQf7TBWKYZqaC3TF4Z5+84csQOXfth8eg34VUFhGdrERbr2MLPKr3TTXY7r\nUgKCwAGexa62zP+IWqrqKvFWBRmAsj3S/BxAJVeinAKR/56UpfRe2OMmPf4ZNWi8\nTybj7KzhVxQiyXeGZz5wjQ==\n-----END PRIVATE KEY-----\n',
    ROLLBAR_ACCESS: '17949957121341bd8cfecac1dd7fa950',
    AMPLITUDE_KEY: 'fe78dea06186648d3c2466600f1783c6'
  };
}

export default config;
