import Rollbar from 'rollbar';
import config from './config/config';

const rollbar = new Rollbar({
  accessToken: config.ROLLBAR_ACCESS,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: config.ROLLBAR_NAME
  },
  enabled: !!config.ROLLBAR_NAME && process.env.NODE_ENV === 'production'
});

export default rollbar;
