// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/compat/app';
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/compat/analytics';
import 'firebase/compat/messaging';

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: 'AIzaSyB3SQ9H50QUUp0lj3KX4-pe3iMOGKaE5jw',
  authDomain: 'np-foundry.firebaseapp.com',
  projectId: 'np-foundry',
  storageBucket: 'np-foundry.appspot.com',
  messagingSenderId: '837325323390',
  appId: '1:837325323390:web:0a0fe2b9ef6fbe000cac2f',
  measurementId: 'G-VXE0MBPXKX'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
