import { COMMON_ACTION_TYPES } from './commonActionTypes';

const initialState = {
  auth: {
    token: null,
    isLoggedIn: 0,
    isAuthenticated: false,
    user: null,
    mobileno: null
  },
  userDetail: {},
  userDetailById: {},
  categories: {
    results: []
  },
  subcategories: {
    results: []
  },
  products: {
    results: []
  },
  productDetail: {},
  cartItems: [],
  favourites: [],
  users: [],
  spares: [],
  apiCallsInProgress: 0,
  initVal: {
    categoryId: '',
    subCategoryId: '',
    scrollpos: 0
  },
  currentstep: COMMON_ACTION_TYPES.BASIC,
  inventory: [],
  spectrometer: null,
  spectrometerList: null,
  spectrometerOverviewData: {},
  spectrometerFilterOptions: {},
  itemInventory: []
};

export default initialState;
