import { SPECTRO_ACTION_TYPES } from './spectroActionTypes';
import initialState from '../initial-state';

export function spectrometerList(
  state = initialState.spectrometerList,
  action
) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.GET_SPECTRO_LIST_SUCCESS:
      return action.spectrometerList;
    case SPECTRO_ACTION_TYPES.ADD_SPECTRO_SUCCESS:
      return { ...state, results: [...state.results, action.spectrometer] };

    case SPECTRO_ACTION_TYPES.UPDATE_SPECTRO_SUCCESS:
      return {
        ...state,
        results: state.results.map(ele =>
          ele.id == action.spectrometer.id ? action.spectrometer : ele
        )
      };

    default:
      return state;
  }
}

export function spectrometer(state = initialState.spectrometer, action) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.GET_SPECTRO_SUCCESS:
      return action.spectrometer;
    case SPECTRO_ACTION_TYPES.CLEAR_SPECTRO:
      return null;

    default:
      return state;
  }
}

export function spectrometerOverviewData(
  state = initialState.spectrometerOverviewData,
  action
) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.SPECTRO_OVERVIEW_DATA_SUCCESS:
      return action.overViewData;

    default:
      return state;
  }
}

export function spectrometerFilterOptions(
  state = initialState.spectrometerFilterOptions,
  action
) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.SPECTRO_FILTER_OPTIONS_SUCCESS:
      return action.options;

    default:
      return state;
  }
}
