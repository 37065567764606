import Rollbar from '../RollBar';

export default function LoadLazyComponent(
  Component,
  retriesLeft = 5,
  interval = 1000
) {
  return new Promise((resolve, reject) => {
    Component()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            Rollbar.error('Bundle chunk failed to load.');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          LoadLazyComponent(Component, retriesLeft - 1, interval).then(
            resolve,
            reject
          );
        }, interval);
      });
  });
}
