import * as spectroApi from '../../api/spectroApi';
import { beginApiCall, apiCallError } from '../apiStatus/apiStatusAction';
import { SPECTRO_ACTION_TYPES } from './spectroActionTypes';

export function getSpectroListSuccess(spectrometerList) {
  return {
    type: SPECTRO_ACTION_TYPES.GET_SPECTRO_LIST_SUCCESS,
    spectrometerList
  };
}

// export function getSpectroSuccess(spectrometer) {
//   return {
//     type: SPECTRO_ACTION_TYPES.GET_SPECTRO_SUCCESS,
//     spectrometer,
//   };
// }

export function addSpectroSuccess(spectrometer) {
  return {
    type: SPECTRO_ACTION_TYPES.ADD_SPECTRO_SUCCESS,
    spectrometer
  };
}

export function updateSpectroSuccess(spectrometer) {
  return {
    type: SPECTRO_ACTION_TYPES.UPDATE_SPECTRO_SUCCESS,
    spectrometer
  };
}

export function updateSpectroOverview(overViewData) {
  return {
    type: SPECTRO_ACTION_TYPES.SPECTRO_OVERVIEW_DATA_SUCCESS,
    overViewData
  };
}

export function updateSpectroFilterOptions(options) {
  return {
    type: SPECTRO_ACTION_TYPES.SPECTRO_FILTER_OPTIONS_SUCCESS,
    options
  };
}

// export function clearSpectrometer() {
//   return {
//     type: SPECTRO_ACTION_TYPES.CLEAR_SPECTRO,
//   };
// }

// export const createSpectrometer = () =>
//   Promise.resolve({
//     type: CREATE_SPECTRO,
//   });

export function getSpectroList(apiParams) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return spectroApi
      .getSpectroList(apiParams)
      .then(spectrometerList => {
        dispatch(getSpectroListSuccess(spectrometerList));
        return spectrometerList;
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}

// export function getSpectroMeter(sm_id) {
//   return function (dispatch) {
//     dispatch(beginApiCall());
//     return spectroApi
//       .getSpectroMeter(sm_id)
//       .then((spectrometer) => {
//         dispatch(getSpectroSuccess(spectrometer));
//         return spectrometer;
//       })
//       .catch((error) => {
//         dispatch(apiCallError());
//         throw error;
//       });
//   };
// }

export function addSpectrometer(data) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return spectroApi
      .AddSpectrometer(data)
      .then(spectrometer => {
        dispatch(addSpectroSuccess(spectrometer));
        return spectrometer;
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}

export function updateSpectrometer(id, data) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return spectroApi
      .UpdateSpectrometer(id, data)
      .then(spectrometer => {
        dispatch(updateSpectroSuccess(spectrometer));
        return spectrometer;
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}

export const createSpectrometer = () => dispatch => {
  dispatch({
    type: 'CREATE_SPECTRO'
  });
  return Promise.resolve();
};

export function getSpectroOverviewData(queryParams) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return spectroApi
      .spectroOverviewData(queryParams)
      .then(spectroOverViewData => {
        const result = {};

        for (const x in spectroOverViewData) {
          result[x] = spectroOverViewData[x].reduce((acc, curr) => {
            acc[curr.sample_type.toLowerCase()] = curr.total;
            return acc;
          }, {});
        }

        dispatch(updateSpectroOverview(result));
        return spectroOverViewData;
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}

export function getSpectroFilterOptions() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return spectroApi
      .spectroFilterOptions()
      .then(options => {
        dispatch(updateSpectroFilterOptions(options));
        return options;
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}
