import { cloneElement } from 'react';
import clsx from 'clsx';

import SvgIcon from '@mui/material/SvgIcon';

import classes from './Icons.module.scss';

export const CustomIcon = props => {
  let { children, onClick, tooltip, color, fontSize, loading, ...rest } = props;

  return cloneElement(children, { ...rest, color, fontSize });
};

export function generateFromSvg(SVG) {
  return props => {
    const { iconProps = {}, fontSize, color, style, sx, ...restProps } = props;
    return (
      <CustomIcon {...restProps}>
        <SvgIcon
          component={SVG}
          inheritViewBox
          style={{ fontSize, color, ...style }}
          className={clsx(
            classes.svg,
            color && classes.svgColor,
            props.strokeWidth && classes.strokeWidth
          )}
          sx={{ ...sx, height: '1em', width: '1em' }}
          {...iconProps}
        />
      </CustomIcon>
    );
  };
}
