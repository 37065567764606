import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import {
  elementsToShow,
  calcOffset,
  validate,
  removeInsignificantZeros,
  isDataWithinRange
} from '../../../utils/Spectrometer';
import { SPECTRO_CONSTS } from '../../../constants/Spectrometer';
import { goBack } from '../../../utils';
import { toast } from 'react-toastify';
import {
  addSpectrometer,
  getSpectroList,
  updateSpectrometer
} from '../../../redux/spectro/spectroAction';
import useQuery from '../../../hooks/useQuery';
import { SpectrometerErrorIcon } from '../../../assets/icons/modifiedIcons';

import classes from './SpectroModal.module.scss';

const defaultTableRows = [
  { showRow: true, showError: true, idx: 1 },
  { showRow: true, idx: 2 },
  { showRow: true, idx: 3 },
  { showRow: true, idx: 4 },
  { showRow: false },
  { showRow: false, avgVal: true }
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const CustomTextField = withStyles({
  root: {
    '& label.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.6) !important'
    },
    '& .MuiOutlinedInput-root': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      '& input.Mui-disabled': {
        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.87) !important'
      }
    }
  }
})(TextField);

const SpectroModal = ({
  cmElements,
  addSpectrometer,
  updateSpectrometer,
  getSpectroList,
  allChargeMix
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [query] = useQuery();

  if (!location.state?.modal) {
    return <Navigate to='..' />;
  }

  const [modal, setModal] = useState({
    ...location.state.modal,
    elements: location.state.modal.elements.length
      ? location.state.modal.elements
      : elementsToShow
  });

  const [elementsList, setElementsList] = useState(
    cmElements.length
      ? cmElements.map(d => d.symbol)
      : modal.elements.length
      ? modal.elements
      : elementsToShow
  );

  useEffect(() => {
    if (elementsList.length !== cmElements.length) {
      setElementsList(cmElements.map(d => d.symbol));
    }
  }, [cmElements]);

  function handleChange(event) {
    const { value, name } = event.target;
    setModal(d => ({ ...d, [name]: { ...d[name], value } }));
  }

  function handleClose() {
    goBack(navigate);
  }

  async function handleSubmit() {
    const { data, hasError, modalData } = validate(modal);

    if (hasError) {
      setModal(modalData);
      return;
    }

    modal.isEdit
      ? await updateSpectrometer(modal.id, data)
      : await addSpectrometer(data);

    const page = query.page ?? SPECTRO_CONSTS.DEFAULT_PAGE_NUM;
    const limit = query.page_size ?? SPECTRO_CONSTS.DEFAULT_PAGE_SIZE;
    const offset = calcOffset(page, limit);
    const search = query.q ?? SPECTRO_CONSTS.DEFAULT_SEARCH_VAL;

    const apiParams = { limit, offset, search };
    getSpectroList(apiParams);

    toast.success(
      `Successfully ${modal.isEdit ? 'updated' : 'added'} reading...`,
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true
      }
    );

    handleClose();
  }

  useEffect(() => {
    const { elementsValue } = modal;
    const newElementsValue = {};

    for (let i = 0; i < defaultTableRows.length; i++) {
      if (defaultTableRows[i].idx) {
        for (const element of modal.elements) {
          if (elementsValue[element]?.[i]) {
            newElementsValue[element] = newElementsValue[element]
              ? [
                  ...newElementsValue[element],
                  {
                    value: removeInsignificantZeros(
                      elementsValue[element][i].value
                    ),
                    error: elementsValue[element][i].error
                  }
                ]
              : [
                  {
                    value: removeInsignificantZeros(
                      elementsValue[element][i].value
                    ),
                    error: elementsValue[element][i].error
                  }
                ];
          } else {
            newElementsValue[element] = newElementsValue[element]
              ? [...newElementsValue[element], { value: '', error: '' }]
              : [{ value: '', error: '' }];
          }
        }
      }
    }

    setModal(d => ({ ...d, elementsValue: newElementsValue }));
  }, [modal.elements]);
  console.log(modal.chargeMix);
  return (
    <Modal
      open={true}
      onClose={handleClose}
      classes={{ root: classes.modalRoot }}
      key={modal.id}
    >
      <div className={classes.modalDiv}>
        <header>
          <div className={classes.headerTxt}>
            {/* {modal.isEdit ? 'Edit Reading' : 'Add New Reading'} */}
            {modal.isEdit ? 'Spectrometer Reading' : 'Add New Reading'}
          </div>
          <CloseIcon className={classes.icon} onClick={handleClose} />
        </header>

        <main>
          <div className={classes.form}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label='Date&Time picker'
                // disabled={modal.isDateEditable}
                disabled={modal.isEdit}
                value={modal.date.value}
                onChange={date =>
                  setModal(d => ({
                    ...d,
                    date: { ...d.date, value: new Date(date).toISOString() }
                  }))
                }
                name='date'
                renderInput={params => <CustomTextField {...params} />}
              />
            </LocalizationProvider>

            <CustomTextField
              id='heat'
              name='heat'
              label='Heat'
              value={modal.heat.value}
              onChange={handleChange}
              error={modal.heat.error.length > 0}
              helperText={modal.heat.error.length ? modal.heat.error : ''}
              variant='outlined'
              disabled={modal.isEdit}
            />

            <CustomTextField
              id='grade'
              name='grade'
              label='Grade'
              value={modal.grade.value}
              onChange={handleChange}
              error={modal.grade.error.length > 0}
              helperText={modal.grade.error.length ? modal.grade.error : ''}
              variant='outlined'
              disabled={modal.isEdit}
            />

            <Autocomplete
              disablePortal
              fullWidth
              id='chargeMix'
              // value={
              //   allChargeMix.find(d => d.id == modal.chargeMix.value) ?? ''
              // }
              value={modal.chargeMix.value ?? ''}
              inputValue={modal.chargeMix.value.cm_name}
              onChange={(_, value) =>
                setModal(d => ({
                  ...d,
                  chargeMix: { ...d.chargeMix, value: value ?? '' }
                }))
              }
              options={allChargeMix}
              getOptionLabel={d => d.cm_name ?? ''}
              renderInput={params => (
                <CustomTextField
                  {...params}
                  error={modal.chargeMix.error.length > 0}
                  helperText={
                    modal.chargeMix.error.length ? modal.chargeMix.error : ''
                  }
                  label='ChargeMix'
                />
              )}
              disabled={modal.isEdit}
            />

            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id='type-label' error={modal.type.error.length > 0}>
                  Type
                </InputLabel>
                <Select
                  labelId='type-label'
                  id='type'
                  name='type'
                  value={modal.type.value}
                  label='Type'
                  error={modal.type.error.length > 0}
                  onChange={handleChange}
                  disabled={modal.isEdit}
                >
                  <MenuItem value='Bath'>BATH</MenuItem>
                  <MenuItem value='Final'>FINAL</MenuItem>
                </Select>
                {modal.type.error.length ? (
                  <FormHelperText error={true}>
                    {modal.type.error}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Box>

            <Autocomplete
              fullWidth
              multiple
              id='elements-label'
              options={elementsList}
              limitTags={3}
              disableCloseOnSelect
              onChange={(_, value) =>
                setModal(d => ({ ...d, elements: value }))
              }
              value={modal.elements}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option}>
                  <Checkbox size='small' checked={selected} />
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '14px' }}
                    primary={option}
                  />
                </MenuItem>
              )}
              ChipProps={{
                classes: {
                  root: classes.autocompleteChip,
                  deleteIcon: classes.autocompleteChipIcon
                },
                size: 'small'
              }}
              renderInput={params => (
                <CustomTextField {...params} label='Elements' />
              )}
              disabled={modal.isEdit}
            />
          </div>

          {modal.elements.length ? (
            <>
              <TableContainer
                component={Paper}
                classes={{ root: classes.modalTableContainer }}
              >
                <Table
                  stickyHeader
                  aria-label='sticky table'
                  classes={{ root: classes.modalTable }}
                >
                  <TableHead classes={{ root: classes.modalTableHead }}>
                    <TableRow>
                      <TableCell
                        className={classes.tableheadcell}
                        width='5%'
                        align='center'
                      >
                        Sl No.
                      </TableCell>
                      {modal.elements.map(element => (
                        <TableCell
                          className={classes.tableheadcell}
                          key={element}
                          align={'center'}
                        >
                          {element}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody classes={{ root: classes.modalTableBody }}>
                    {defaultTableRows.map((row, i) => {
                      if (
                        modal.isEdit &&
                        i >= modal.rawSpectroMeasurements.length
                      )
                        return null;

                      return (
                        <TableRow key={i}>
                          {row.showRow ? (
                            <>
                              <TableCell
                                component='th'
                                scope='row'
                                align='center'
                                width='5%'
                              >
                                {row.idx}
                              </TableCell>

                              {modal.elements.map(element => (
                                <TableCell key={element} align={'center'}>
                                  <CustomTextField
                                    id={element}
                                    name={element}
                                    variant='outlined'
                                    InputProps={{
                                      inputProps: {
                                        min: 0,
                                        max: 92,
                                        style: {
                                          textAlign: 'center',
                                          minWidth: 60,
                                          fontSize: 13,
                                          padding: '5px 10px'
                                        }
                                      },
                                      notched: false
                                    }}
                                    label={element}
                                    type='number'
                                    size='small'
                                    InputLabelProps={{
                                      style: { display: 'none' }
                                    }}
                                    value={
                                      modal.elementsValue[element]?.[i]?.value
                                    }
                                    error={
                                      modal.elementsValue[element]?.[i]?.error
                                        .length > 0 ||
                                      (modal.error.length > 0 && i === 0)
                                    }
                                    onChange={event =>
                                      setModal(d => {
                                        d.elementsValue[element][i].value =
                                          event.target.value;

                                        return { ...d };
                                      })
                                    }
                                    disabled={modal.isEdit}
                                  />
                                </TableCell>
                              ))}
                            </>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  <TableFooter
                    // component="div"
                    style={{
                      left: 0,
                      bottom: 0, // <-- KEY
                      zIndex: 2,
                      position: 'sticky',
                      backgroundColor: 'rgb(230,241,250)'
                    }}
                    classes={{ root: classes.modalTableFooter }}
                  >
                    <TableRow>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{
                          fontWeight: '600',
                          color: '#000',
                          fontSize: '0.9rem'
                        }}
                      >
                        Average
                      </TableCell>

                      {modal.elements.map(element => {
                        const length = modal.elementsValue[element]?.reduce(
                          (acc, curr) =>
                            curr.value.length ? (acc = acc + 1) : acc,
                          0
                        );

                        let dataInRange = true;

                        let value;

                        if (length) {
                          value =
                            modal.elementsValue[element].reduce(
                              (acc, curr) => +acc + +curr.value,
                              0
                            ) / length;
                        }

                        if (
                          modal.isEdit &&
                          length &&
                          modal.elementsToShow.includes(element) &&
                          modal.elementRange[element]
                        ) {
                          dataInRange = isDataWithinRange(
                            value,
                            modal.elementRange[element]
                          );
                        }

                        return (
                          <TableCell
                            key={element}
                            align={'center'}
                            style={{
                              fontWeight: '600',
                              color: '#000',
                              fontSize: '0.9rem'
                            }}
                          >
                            {length &&
                              (dataInRange ? (
                                value.toFixed(4)
                              ) : (
                                <span
                                  style={{
                                    background: '#FFF2F2',
                                    border: '1px solid #FF3D60',
                                    borderRadius: 4,
                                    padding: '5px 15px'
                                  }}
                                >
                                  {value.toFixed(4)}
                                </span>
                              ))}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
          ) : null}
        </main>

        <footer
          className={clsx(
            !modal.isEdit
              ? classes.modalAddFooter
              : classes.spectroSuggestionsContainer
          )}
        >
          {modal.error.length ? (
            <FormHelperText error style={{ fontSize: 15 }}>
              {modal.error}
            </FormHelperText>
          ) : (
            <div />
          )}

          {!modal.isEdit && modal.elements.length ? (
            <div
              onClick={handleSubmit}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <button className={classes.tcSave}>
                <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
                <span>Save Reading</span>
              </button>
            </div>
          ) : null}

          {modal.isEdit && Object.keys(modal.elementRange).length ? (
            <div className={classes.spectroSuggestions}>
              <div className={classes.header}>
                <SpectrometerErrorIcon />
                <span>Current Chemistry does not meet Target Chemistry.</span>
              </div>

              <div className={classes.suggestion}>
                Add the following to meet the Target Chemistry:{' '}
                <span
                  style={{
                    background: '#FFF2F2',
                    border: '1px solid #FF3D60',
                    borderRadius: 4,
                    padding: '2px 8px',
                    fontWeight: 600
                  }}
                >
                  2kg Carburizer
                </span>{' '}
                <span style={{ fontWeight: 600 }}>+</span>{' '}
                <span
                  style={{
                    background: '#FFF2F2',
                    border: '1px solid #FF3D60',
                    borderRadius: 4,
                    padding: '2px 8px',
                    fontWeight: 600
                  }}
                >
                  1.5kg FeSi
                </span>
              </div>

              <div className={classes.estimate}>
                Estimated Average:{' '}
                {modal.elements.map(element => {
                  const length = modal.elementsValue[element]?.reduce(
                    (acc, curr) => (curr.value.length ? (acc = acc + 1) : acc),
                    0
                  );

                  let dataInRange = true;

                  let value;

                  if (length) {
                    value =
                      modal.elementsValue[element].reduce(
                        (acc, curr) => +acc + +curr.value,
                        0
                      ) / length;
                  }

                  if (
                    length &&
                    modal.elementsToShow.includes(element) &&
                    modal.elementRange[element]
                  ) {
                    dataInRange = isDataWithinRange(
                      value,
                      modal.elementRange[element]
                    );
                  }

                  if (!dataInRange) {
                    return (
                      <span
                        style={{
                          background: '#FFF2F2',
                          border: '1px solid #FF3D60',
                          borderRadius: 4,
                          padding: '2px 8px',
                          fontWeight: 600,
                          margin: '0 5px'
                        }}
                      >
                        {element}: {modal.elementRange[element].max}
                      </span>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          ) : null}
        </footer>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    cmElements: state.chargeMix.cm_elements.data ?? [],
    allChargeMix: state.chargeMix.all.data ?? []
  };
};

const mapDispatchToProps = {
  addSpectrometer,
  updateSpectrometer,
  getSpectroList
};

export default connect(mapStateToProps, mapDispatchToProps)(SpectroModal);
