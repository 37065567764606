import amplitude from 'amplitude-js';
import config from '../config/config';
import { isProduction } from '.';

export async function Add_Amplitude_Event_Analytics(event_name, event_props) {
  if (isProduction()) {
    amplitude.getInstance().logEvent(event_name, event_props);
  }
}

export async function Initialize_Amplitude() {
  if (isProduction()) {
    amplitude.getInstance().init(`${config.AMPLITUDE_KEY}`, null, {
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true
    });
  }
}

export async function Set_UserId_Amplitude(userDetail) {
  if (isProduction()) {
    if (Object.keys(userDetail).length > 0) {
      var UserName = `${
        userDetail.customer.company_name.substring(0, 6) +
        '_' +
        userDetail.name.substring(0, 6) +
        '_' +
        userDetail.id
      }`;
      amplitude.getInstance().setUserId(UserName);
    }
  }
}
