import { combineReducers } from 'redux';
import auth from './auth/authReducer';
import apiCallsInProgress from './apiStatus/apiStatusReducer';
import categories from './categories/categoriesReducer';
import subcategories from './subcategories/subcategoriesReducer';
import products from './product/productReducer';
import cartItems from './cartItem/cartItemReducer';
import productDetail from './productDetail/productDetailReducer';
import userDetail from './userDetail/userDetailReducer';
import userDetailById from './userDetail/userDetailByIdReducer';
import favourites from './favourite/favouriteReducer';
import users from './user/userReducer';
import spares from './spare/spareReducer';
import initVal from './init/initReducer';
import inventory from './inventory/inventoryReducer';
import adminViewReducer from './reducers/adminView';
import uploadFileReducer from './reducers/uploadFile';
import chargeMixDemoReducer from './reducers/chargeMixDemo';
import { itemInventory, currentstep } from './chargeMix/chargeMixReducers';
import {
  spectrometerList,
  spectrometer,
  spectrometerOverviewData,
  spectrometerFilterOptions
} from './spectro/spectroReducer';
import { createFromDefaultReducer } from '../redux/reducers';
import * as actionTypes from './actionTypes';

const appReducer = combineReducers({
  auth,
  apiCallsInProgress,
  categories,
  subcategories,
  products,
  cartItems,
  shippingaddress: createFromDefaultReducer(actionTypes.LOAD_ADDRESS_BY_ID),
  orders: createFromDefaultReducer(actionTypes.LOAD_ORDER),
  productDetail,
  userDetail,
  userDetailById,
  orderDetail: createFromDefaultReducer(actionTypes.LOAD_ORDER_DETAILS),
  uploadFile: uploadFileReducer,
  favourites,
  users,
  spares,
  initVal,

  chargeMix: combineReducers({
    read: createFromDefaultReducer(actionTypes.FETCH_CHARGEMIX),
    all: createFromDefaultReducer(actionTypes.FETCH_ALL_CHARGEMIX),
    list: createFromDefaultReducer(actionTypes.FETCH_CHARGEMIX_LIST),
    cm_elements: createFromDefaultReducer(actionTypes.FETCH_CHARGEMIX_ELEMENTS),
    overview: createFromDefaultReducer(actionTypes.CHARGEMIX_OVERVIEW),
    demo: chargeMixDemoReducer
  }),

  currentstep,
  inventory,
  itemInventory,
  spectrometerList,
  spectrometer,
  spectrometerOverviewData,
  spectrometerFilterOptions,
  adminView: adminViewReducer
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state [Ref: https://medium.com/@asher.cassetto.cohen/resetting-redux-state-with-a-root-reducer-bonus-how-to-reset-state-selectively-e2a008d0de61]
  if (action.type === 'LOGOUT_APP') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
