import parse from 'date-fns/parseISO';
import format from 'date-fns/format';
import { toast } from 'react-toastify';
import flatten from 'flat';
import { toDate, differenceInDays } from 'date-fns';
import config from '../config/config';

export function is8601_to_readable(iso8601_string) {
  if (iso8601_string) {
    return format(parse(iso8601_string), ['MMM dd, yyyy HH:mm:SS ']);
  } else {
    return null;
  }
}

export function is8601_to_readable_date(iso8601_string) {
  if (iso8601_string) {
    return format(parse(iso8601_string), ['MMM dd, yyyy']);
  } else {
    return null;
  }
}

export function is8601_to_readable_datetime(iso8601_string) {
  if (iso8601_string) {
    let Date_Time = [];
    Date_Time[0] = format(parse(iso8601_string), ['MMM dd, yyyy']);
    Date_Time[1] = format(parse(iso8601_string), ['HH:mm:SS']);
    return Date_Time;
  } else {
    return null;
  }
}

export function calculateDifferenceInDays(startDate, endDate) {
  return differenceInDays(
    new Date(
      startDate
        .toLocaleString('en-us', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2') // Format to YYYY-MM-DD
    ),
    new Date(
      endDate
        .toLocaleString('en-us', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2') // Format to YYYY-MM-DD
    )
  );
}

/*
Expectation: 
* APIs or whatevers will throw a javascript Error object.
* Error.message will contain the string (which is actually a json)
that contains the standard django error formatted json.
* we jsonify that string and display some error toasts
* the standard django error obj is:
{
  <fieldName> : [<array of string messages>]
}
*/
export function errorToaster(error) {
  if (typeof error !== 'string') {
    const errorObj = JSON.parse(error);

    // a django error object will have deep nesting depending on the request
    // lets flatten and display all the error messages
    const flatshiz = flatten(errorObj);

    console.log(
      "Hey developer, here's a more detailed error for you: ",
      flatshiz
    );

    for (const key in flatshiz) {
      toast.error(flatshiz[key]);
    }
  } else {
    toast.error(error);
  }
}

export function isAuthenticated() {
  return localStorage.getItem('tokenv2') &&
    localStorage.getItem('tokenv2') != 'Token '
    ? true
    : false;
}

export function goBack(navigate) {
  window.history.length > 2 ? navigate(-1) : navigate('/');
}

export function formatDate(date, dateformat) {
  return format(toDate(date), dateformat);
}

export function makeNumRound(num) {
  return Math.round(num);
}

export function makedecimalupto2digit(num) {
  return Number.parseFloat(num).toFixed(2);
}

export function random_item(items) {
  var todayDate = formatDate(new Date(), 'dd');

  return items[todayDate % 3];
}

export function random_from_array(array) {
  var index = Math.floor(Math.random() * array.length);
  return array[index];
}

export function notify_slack_person(user) {
  var slack_notifier = `<!channel>`;
  if (user.rm && user.rm.slack_id) {
    slack_notifier = `<@${user.rm.slack_id}>`;
  }
  return slack_notifier;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createQueryParam(apiParams) {
  const queryParam = [];

  for (const index in apiParams) {
    if (apiParams[index].toString().length) {
      queryParam.push(`${index}=${apiParams[index]}`);
    }
  }

  return queryParam.join('&');
}

export function isProduction() {
  return config.ENV === 'production';
}

export async function withDefaultUIHandler(
  fn,
  {
    successMsg = false,
    errorMsg = true,
    propagateError = true,
    onErrorHandler = null
  }
) {
  try {
    const data = await fn();

    if (successMsg) {
      toast.success(successMsg === true ? 'Success...' : successMsg);
    }

    return data;
  } catch (error) {
    if (errorMsg) {
      errorMsg === true
        ? errorToaster(
            error.message ?? 'Something went wrong. Please try again.'
          )
        : errorToaster(errorMsg);
    }

    if (onErrorHandler) onErrorHandler();

    if (propagateError) {
      throw error;
    }
  }
}

export function rupeeFormat(number) {
  if (!number) {
    // Empty string found
    return '₹00.00';
  }

  return new Intl.NumberFormat('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR'
  }).format(number);
}
