import React from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { random_item } from '../../utils';

function SpinnerWrapper() {
  var LoaderText = [
    'Check market prices of FerroAlloys and Pig Iron in WhatsApp',
    'Sell old grinding wheels and buy new ones!',
    'Connect your inventory to NowPurchase to automatically create orders'
  ];
  var location = useLocation();
  var RandomLoadText;
  var pathnamearr = location.pathname.split('/').filter(ele => ele != '');
  if (pathnamearr.includes('chargemix')) {
    RandomLoadText =
      'Proprietary technology to help you select the right Charge Mix based on your inventory, market prices & supply.';
  } else {
    RandomLoadText = random_item(LoaderText);
  }
  return (
    <div className='flex flexcenter colflex spinnerbody'>
      <div>
        <Spinner color='success' style={{ width: '2rem', height: '2rem' }} />
      </div>
      <div className='randomtext'>{RandomLoadText}</div>
    </div>
  );
}
export default SpinnerWrapper;
