import { toast } from 'react-toastify';

export const calcOffset = (pageNum, limit) => {
  return (pageNum - 1) * limit;
};

export const elementsToShow = [
  'C',
  'Mn',
  'Si',
  'S',
  'P',
  'Mg',
  'Cr',
  'Cu',
  'Ni'
];

export function removeInsignificantZeros(num) {
  if (!num && num !== 0) return '';

  let result = num;

  if (typeof result !== 'number') {
    result = Number(result);
  }

  return Number(result.toFixed(4)).toString();
}

export const INITIAL_MODAL_STATE = {
  open: false,
  date: { value: new Date(), error: '' },
  heat: { value: '', error: '' },
  grade: { value: '', error: '' },
  chargeMix: { value: '', error: '' },
  type: { value: '', error: '' },
  elements: [],
  elementsValue: {},
  isEdit: false,
  error: ''
};

export function validate(obj) {
  let hasError = false;
  const modalData = { ...obj };
  const emptyRowsIdx = new Set();
  let data;

  for (const x in modalData) {
    if (
      [
        'open',
        'elements',
        'isEdit',
        'date',
        'error',
        'id',
        'customer',
        'rawSpectroMeasurements',
        'isDateEditable'
      ].includes(x)
    ) {
      continue;
    }

    if (x === 'elementsValue') {
      let hasTableData = false;

      for (
        let i = 0;
        i < modalData.elementsValue[modalData.elements[0]].length;
        i++
      ) {
        let hasRowData = false;

        for (const element of modalData.elements) {
          if (modalData.elementsValue[element][i].value.length) {
            hasTableData = true;
            hasRowData = true;
          }
        }

        if (hasRowData) {
          for (const element of modalData.elements) {
            const currentElement = modalData.elementsValue[element][i];

            if (!currentElement.value.length) {
              // currentElement.error = "This field is required.";
              // hasError = true;
            } else if (currentElement.value >= 98) {
              const msg = 'Value should be between 0 and 98';

              toast.error(msg, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true
              });

              currentElement.error = msg;
              hasError = true;
            } else {
              currentElement.error = '';
            }
          }
        } else {
          emptyRowsIdx.add(i);
        }
      }

      if (!hasTableData) {
        toast.error('Atleast one reading required', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true
        });

        modalData.error = 'Atleast one reading is required';

        hasError = true;
      }

      continue;
    }

    if (!modalData[x].value.toString().length) {
      modalData[x].error = 'This field is required.';
      hasError = true;
    } else {
      modalData[x].error = '';
    }
  }

  if (!hasError) {
    const copy = JSON.parse(JSON.stringify(modalData));

    for (const element of copy.elements) {
      copy.elementsValue[element] = copy.elementsValue[element].filter(
        (_, i) => !emptyRowsIdx.has(i)
      );
    }

    const spectro_measurements = [];
    const reading_avg = [];

    for (const x in copy.elementsValue) {
      for (let i = 0; i < copy.elementsValue[x].length; i++) {
        if (!spectro_measurements[i]) {
          spectro_measurements[i] = {
            id: copy.rawSpectroMeasurements?.[i]?.id,
            spectro_readings: []
          };
        }

        if (copy.elementsValue[x][i].value.length) {
          spectro_measurements[i].spectro_readings.push({
            element_symbol: x,
            recovery_rate: copy.elementsValue[x][i].value
          });
        }
      }
    }

    for (const x in copy.elementsValue) {
      const length = copy.elementsValue[x]?.reduce(
        (acc, curr) => (curr.value.length ? (acc = acc + 1) : acc),
        0
      );

      if (length) {
        reading_avg.push({
          recovery_rate:
            copy.elementsValue[x].reduce((acc, curr) => +acc + +curr.value, 0) /
            length,
          element_symbol: x
        });
      }
    }

    data = {
      id: copy.id,
      reading_created_at: copy.date.value,
      cm_id: {
        ...copy.chargeMix.value,
        cm_target_chemistry: [
          {
            id: 12399,
            element: 1,
            element__name: 'Carbon',
            element__symbol: 'C',
            ele_range: '3.5-3.6'
          },
          {
            id: 12400,
            element: 2,
            element__name: 'Silicon',
            element__symbol: 'Si',
            ele_range: '2.5-2.6'
          },
          {
            id: 12401,
            element: 7,
            element__name: 'Manganese',
            element__symbol: 'Mn',
            ele_range: '0.4-0.6'
          },
          {
            id: 12402,
            element: 3,
            element__name: 'Sulphur',
            element__symbol: 'S',
            ele_range: '0.005-0.02'
          },
          {
            id: 12403,
            element: 4,
            element__name: 'Phosphorous',
            element__symbol: 'P',
            ele_range: '0.05-0.07'
          },
          {
            id: 12404,
            element: 6,
            element__name: 'Magnesium',
            element__symbol: 'Mg',
            ele_range: '0.04-0.05'
          }
        ]
      },
      grade: copy.grade.value,
      heat_no: copy.heat.value,
      spectro_measurements,
      reading_avg,
      sample_type: copy.type.value
    };
  }

  return { hasError, data, modalData };
}

function getSpectroMeasurements(spectroMeasurements) {
  if (!spectroMeasurements) return;

  const readings = {};

  for (let i = 0; i < spectroMeasurements.length; i++) {
    const { spectro_readings } = spectroMeasurements[i];

    for (const { element_symbol, recovery_rate } of spectro_readings) {
      if (!readings[element_symbol]) {
        readings[element_symbol] = [];
      }

      readings[element_symbol][i] = recovery_rate;
    }
  }

  for (const x in readings) {
    for (let i = 0; i < readings[x].length; i++) {
      if (!readings[x][i]) readings[x][i] = '';
    }
  }

  return readings;
}

export function getRowActionData(row) {
  const data = JSON.parse(JSON.stringify(INITIAL_MODAL_STATE));

  data.chargeMix.value = row.cm_id ?? '';
  data.date.value = new Date(row.reading_created_at);
  data.heat.value = row.heat_no ?? '';
  data.type.value = row.sample_type ?? '';
  data.id = row.id;
  data.customer = row.customer;
  data.grade.value = row.grade ?? '';
  data.elementsValue = getSpectroMeasurements(row.spectro_measurements);
  data.open = true;
  data.isEdit = true;
  data.rawSpectroMeasurements = row.spectro_measurements;
  data.isDateEditable = !!row.spectro_xml_file;
  data.elementRange = row.cm_id?.cm_target_chemistry.reduce((acc, curr) => {
    const [min, max] = curr.ele_range.split('-');
    acc[curr.element__symbol] = { min, max };
    return acc;
  }, {});
  data.elementsToShow = elementsToShow;

  for (const x in data.elementsValue) {
    for (let i = 0; i < data.elementsValue[x].length; i++) {
      data.elementsValue[x][i] = {
        value: data.elementsValue[x][i],
        error: ''
      };
    }
  }

  data.elements = Object.keys(data.elementsValue);

  return data;
}

export function handleRowAction(event, data, navigate, location) {
  event.stopPropagation();
  event.preventDefault();

  return openModal(`${data.id}`, data, navigate, location);
}

export function openModal(to, data, navigate, location) {
  return navigate(to, {
    state: { backgroundLocation: { ...location }, modal: data }
  });
}

export function isDataWithinRange(val, { min, max }) {
  return Number(val) >= Number(min) && Number(val) <= Number(max);
}
