import { cFetch, token } from './apiUtils';
import config from '../config/config';

export function getUserDetails() {
  return cFetch(`${config.API_URL}/api/user-detail/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}

/**
 * this function is used to get userdetail by id
 * @param {integer} UserId
 * return api response of userdetail
 */
export function getUserDetailbyUserId(UserId) {
  return cFetch(`${config.API_URL}/api/r/users/${UserId}/`, {
    method: 'GET'
  });
}
