import React from 'react';

import Rollbar from '../../RollBar';
import config from '../../config/config';
import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    Rollbar.error(`${error}, Info: ${JSON.stringify(errorInfo)}`);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{ display: 'grid', placeItems: 'center', minHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <img
              src={`${config.IMGIX_URL}MetalCloud.png?auto=format&auto=compress&w=120`}
            />
            <div
              className='randomtext'
              style={{ margin: '10px 0 5px 0', fontSize: '1.5rem' }}
            >
              Oops! Something went wrong.
            </div>
            <button
              className='errorBoundaryBtn'
              onClick={() => window.location.reload()}
              style={{ minWidth: 120, fontSize: '1rem' }}
            >
              Retry
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
