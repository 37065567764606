import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import clsx from 'clsx';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { token } from './api/apiUtils';
import './common.scss';
import SpinnerWrapper from './components/common/SpinnerWrapper';
import firebase from './components/firebase';
import LoadLazyComponent from './components/LoadLazyComponent';
import OrientationPopUp from './components/OrientationPopUp';
import ErrorBoundary from './componentsV2/ErrorBoundary/ErrorBoundary';
import SpectroModal from './componentsV2/Spectrometer/SpectroModal';
import config from './config/config';
import './css/common.css';
import { loadUserDetail } from './redux/userDetail/userDetailAction';
import Rollbar from './RollBar';
import { isAuthenticated } from './utils';
import {
  Add_Amplitude_Event_Analytics,
  Initialize_Amplitude,
  Set_UserId_Amplitude
} from './utils/amplitudeAnalytics';
import {
  AnalyticsPageView,
  ViewUserActivityAnalytics
} from './utils/analyticsUtils';

// mui V5 Theme for TextField
const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '13px',
          top: 2
        }
      },
      styleOverrides: {
        shrink: ({ ownerState, theme }) => ({
          ...(ownerState.shrink && {
            fontSize: '1rem !important',
            top: '-1 !important'
          })
        })
      }
    }
  }
});

// Route Components
const Login = lazy(() =>
  LoadLazyComponent(() => import('./pages/Login/Login'))
);
const SignUp = lazy(() =>
  LoadLazyComponent(() => import('./pages/SignUp/SignUp'))
);
const CategoryProductListingPage = lazy(() =>
  LoadLazyComponent(() =>
    import('./pages/CategoryProductListing/CategoryProductListing')
  )
);
// const Checkout = lazy(() =>
//   LoadLazyComponent(() => import('./pages/Checkout/Checkout'))
// );
const Vendor = lazy(() =>
  LoadLazyComponent(() => import('./pages/Vendor/Vendor'))
);
const OrderHistory = lazy(() =>
  LoadLazyComponent(() => import('./pages/OrderHistory/OrderHistory'))
);
const OTPLogin = lazy(() =>
  LoadLazyComponent(() => import('./pages/OTPLogin/OTPLogin'))
);
const GSTLogin = lazy(() =>
  LoadLazyComponent(() => import('./pages/GSTLogin/GSTLogin'))
);
const ProductDetail = lazy(() =>
  LoadLazyComponent(() => import('./pages/ProductDetail/ProductDetail'))
);
const OrderDetail = lazy(() =>
  LoadLazyComponent(() => import('./pages/OrderDetail/OrderDetail'))
);
const RFQForm = lazy(() => LoadLazyComponent(() => import('./pages/RFQ/RFQ')));
const ReadSheet = lazy(() =>
  LoadLazyComponent(() => import('./pages/Quotation/Quotation'))
);
const SpareListing = lazy(() =>
  LoadLazyComponent(() => import('./pages/SpareListing/SpareListing'))
);
const ChargeMixApp = lazy(() =>
  LoadLazyComponent(() => import('./pages/ChargeMix/ChargeMix'))
);
const DashBoard = lazy(() =>
  LoadLazyComponent(() => import('./pages/CMDashboard/CMDashboard'))
);
const Spectrometer = lazy(() =>
  LoadLazyComponent(() => import('./pages/Spectrometer'))
);
const PowerInsight = lazy(() =>
  LoadLazyComponent(() => import('./pages/PowerInsight'))
);
const Inventory = lazy(() =>
  LoadLazyComponent(() => import('./pages/Inventory/Inventory'))
);
const LogReport = lazy(() =>
  LoadLazyComponent(() => import('./pages/LogReport'))
);
const LogReportDetails = lazy(() =>
  LoadLazyComponent(() => import('./pages/LogReportDetails'))
);

const SecretRouter = () => {
  if (isAuthenticated() === true) return <Outlet />;

  toast.info('Please login to view this page ...', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true
  });

  return (
    <Navigate
      to={{
        pathname: '/login',
        state: { prevLocation: location.pathname }
      }}
    />
  );
};

const App = ({ loadUserDetail, userDetail }) => {
  const maintenancePage = false;
  const [modalActive, setModalActive] = useState(false);
  const [appLoading, setAppLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    set_page_title(location.pathname);
    AnalyticsPageView(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    set_page_title(window.location.pathname);
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      Notification.requestPermission()
        .then(() => {
          return messaging.getToken({
            vapidKey:
              'BMBTetOw4qXoZGYIpXv4jeLVXPPPclRGFa2vQ0ppgFrIv-9EpbdlujaNy13PuuSpNRpT5EDeoUiLGB3JxRepQYw'
          });
        })
        .then(currentToken => {
          if (currentToken) {
            console.log('Current Token', currentToken);
            Rollbar.info(`Current Token : ${currentToken}`);
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.'
            );
            Rollbar.info(
              `No registration token available. Request permission to generate one.`
            );
            // ...
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
          Rollbar.info(`An error occurred while retrieving token. : ${err}`);
          // ...
        });
      messaging.onMessage(payload => {
        // console.log("Message received.1 ", payload);
        const { title, body } = payload.notification;
        const notificationOptions = {
          body,
          icon: 'https://img1.nowpurchase.com/assets/images/logo.png?dpi=64&pad=10&w=128&h=128&auto=enhance'
        };
        const notification = new Notification(title, notificationOptions);
        notification.onclick = function () {
          window.open('/', '_self');
          notification.close();
        };
      });
    }
  }, []);

  const tokenV2 = token();

  useEffect(() => {
    if (
      !tokenV2 ||
      (Object.keys(userDetail).length > 0 && !userDetail.is_staff)
    ) {
      ReactGA.initialize(`${config.GA_ACCESS_CODE}`);
      ReactGA.ga('require', 'ec');
      AnalyticsPageView(window.location.pathname + window.location.search);
    }
    if (Object.keys(userDetail).length > 0 && !userDetail.is_staff) {
      const UserName_Id = `${userDetail.name}_${userDetail.id}`;
      if (isAuthenticated() === true) {
        ViewUserActivityAnalytics(UserName_Id);
      }
    }
  }, [userDetail]);

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      tokenV2 &&
      Object.keys(userDetail).length > 0
    ) {
      window.Tawk_API = window.Tawk_API || {};

      window.Tawk_LoadStart = new Date();

      // pass attributes to tawk.to on widget load
      window.Tawk_API.onLoad = () => {
        window.Tawk_API.setAttributes(
          {
            name: userDetail.name,
            email: userDetail.email
          },
          err => {}
        );
      };

      window.Tawk_API.onChatMaximized = () => {
        Add_Amplitude_Event_Analytics('WEB_CHAT_TWAK.TO', {
          description: `Clicked on tawk.to widget.`
        });
      };

      const tawk = document.getElementById('tawkId');
      if (tawk) {
        // Prevent TawkTo to create root script if it already exists
        return window.Tawk_API;
      }

      const script = document.createElement('script');
      script.id = 'tawkId';
      script.async = true;
      script.src = `https://embed.tawk.to/${config.TAWK_API_KEY}/${config.TAWK_CHAT_ID}`;
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');

      const first_script_tag = document.getElementsByTagName('script')[0];
      if (!first_script_tag || !first_script_tag.parentNode) {
        throw new Error('DOM is unavailable');
      }

      first_script_tag.parentNode.insertBefore(script, first_script_tag);
    }
  }, [userDetail]);

  useEffect(() => {
    tokenV2 && loadUserDetail();

    window.addEventListener('beforeinstallprompt', e => {
      console.log('event', e);
      e.userChoice.then(choiceResult => {
        ReactGA.ga('send', 'event', 'A2H', choiceResult.outcome); // either "accepted" or "dismissed"
      });
    });
    setAppLoading(false);
  }, []);

  /* Amplitude Integration */

  useEffect(() => {
    //if (process.env.NODE_ENV === "production") {
    Initialize_Amplitude();
    //}
    Add_Amplitude_Event_Analytics(`PG_${document.title}`, {
      description: `Opened ${window.location.pathname} Page`
    });
  }, []);

  useEffect(() => {
    Set_UserId_Amplitude(userDetail);
  }, [userDetail]);

  useEffect(() => {
    Add_Amplitude_Event_Analytics(`PG_${document.title}`, {
      description: `Opened ${location.pathname} Page`
    });
  }, [location]);

  const set_page_title = path => {
    let pageName = 'NowPurchase Foundry';

    const page = {
      '^/$': 'Dashboard',
      '^/inventory$': 'Inventory',
      '^/orders$': 'Orders',
      '^/order/[0-9]+/$': 'Order Details',
      '^/product/[0-9]+$': 'Product Details',
      '^/cm/chargemix/$': 'ChargeMix',
      '^/cm/chargemix/wizard/[0-9]+$': 'ChargeMix Details',
      '^/cm/chargemix/wizard/$': 'ChargeMix Create',
      '^/login$': 'Login',
      '^/OTPlogin$': 'Enter OTP',
      '^/signup$': 'Sign Up',
      '^/quote$': 'Raise Rfq',
      '^/spectrometer$': 'Spectrometer',
      '^/spectrometer/[0-9]+$': 'Spectrometer Readings',
      '^/report$': 'Log Report',
      '^/report/[0-9]+$': 'Log Details',
      '^/power_insight$': 'Power Insight'
    };

    Object.keys(page).forEach(pg => {
      if (new RegExp(pg).test(path)) pageName = page[pg];
    });

    document.title = pageName;
  };

  /* Amplitude Integration */

  window.addEventListener('orientationchange', function () {
    let screenOrientation = window.orientation;
    if (screenOrientation && screenOrientation != 0) {
      setModalActive(true);
    } else {
      setModalActive(false);
    }
  });

  if (maintenancePage)
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <img src='https://i.pinimg.com/originals/4e/e5/ca/4ee5ca990c4a7ec5ff5207719caa2e5c.png' />
      </div>
    );

  if (appLoading) return <SpinnerWrapper />;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={clsx(tokenV2 && 'app')}>
          <OrientationPopUp modalActive={modalActive} />
          <ToastContainer autoClose={7000} />

          <Suspense fallback={<SpinnerWrapper />}>
            <ErrorBoundary>
              <Routes location={location.state?.backgroundLocation || location}>
                <Route element={<SecretRouter />}>
                  <Route path='/' element={<DashBoard />} />
                  <Route path='cm/*' element={<ChargeMixApp />} />
                  <Route path='/orders' element={<OrderHistory />} />
                  <Route path='/order/:id' element={<OrderDetail />} />
                  <Route path='/power_insight' element={<PowerInsight />} />
                  <Route path='/inventory' element={<Inventory />} />
                  <Route path='/report/:id' element={<LogReportDetails />} />
                  <Route path='/report' element={<LogReport />} />
                  <Route path='/quote' element={<RFQForm />} />

                  <Route path='spectrometer'>
                    <Route index element={<Spectrometer />} />
                    <Route path=':id' element={<SpectroModal />} />
                    <Route path='add' element={<SpectroModal />} />
                  </Route>

                  {/* <Route path="/spares/:id" element={<SpareListing />} /> */}
                  {/* <Route path="/product/:id" element={<ProductDetail />} /> */}
                  {/* <Route path="/vendor" element={<Vendor />} /> */}
                  {/* <Route path="/checkout" element={<Checkout />} /> */}
                  {/* <Route path="store">
                      <Route index element={<CategoryProductListingPage />} />
                      <Route
                        path="c/:CatSlug"
                        element={<CategoryProductListingPage />}
                      />
                      <Route
                        path="c/:CatSlug/:SubCatSlug"
                        element={<CategoryProductListingPage />}
                      />
                    </Route> */}
                </Route>
                <Route path='/login' element={<Login />} />
                <Route path='/OTPlogin' element={<OTPLogin />} />
                <Route path='/GSTlogin' element={<GSTLogin />} />
                <Route path='/signup' element={<SignUp />} />
                <Route path='/quotation/:id' element={<ReadSheet />} />

                {/* <Route path="/allproducts" element={CatProductListing} /> */}
                <Route path='*' element={<Navigate to='/' replace />} />
              </Routes>

              {location.state?.backgroundLocation && (
                <Routes>
                  <Route element={<SecretRouter />}>
                    <Route path='spectrometer'>
                      <Route path=':id' element={<SpectroModal />} />
                      <Route path='add' element={<SpectroModal />} />
                    </Route>
                  </Route>
                </Routes>
              )}
            </ErrorBoundary>
          </Suspense>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.apiCallsInProgress > 0,
    userDetail: state.userDetail
  };
}

const mapDispatchToProps = {
  loadUserDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
